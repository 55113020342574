<style>
.v-modal{
  z-index: 100 !important;
}
</style>

<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row>
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="协议名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="280" class-name="small-padding fixed-width">
        <template  slot-scope="scope">
          <el-row>
            <router-link :to="'/site/agreementDetail?id='+scope.row.id">
              <el-button type="primary" size="mini">查看详情</el-button>
            </router-link>
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)" style="margin-left: 10px;">编辑</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <el-dialog title="编辑" :visible.sync="showEdit" v-if = "showEdit">
      <el-form ref="form" :model="form" label-position="left" label-width="80px">
        <el-form-item label="协议内容" prop="content">
          <tinymce :height="400" v-model="form.content" :value="form.content" ref="tinymce" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import request from '@/utils/request'
import Tinymce from '@/components/Tinymce'
export default {
  components: { Tinymce },
  data() {
    return {
      list: [],
      total: null,
      listLoading: true,
      btnLoading: false,
      showEdit:false,    //showEdit 编辑
      listQuery: {
        page: 1,
        limit: 10
      },
      form: {
        id: '',
        content:'',
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      request({
        url: '/api/backend/agreement/list',
        method: 'get',
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data
        this.total = response.data.total
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },

    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    resetForm() {
      this.form = {
        id: '',
        content: '',
      },
      this.btnLoading = false
    },
    handleEdit(row) {
      this.resetForm()
      let form = Object.assign({}, row) // copy obj
      this.form = form;
      this.showEdit = true
      if(this.$refs.tinymce) this.$refs.tinymce.setContent(row.content||'')
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          request({
            url: '/api/backend/agreement/save',
            method: 'post',
            data: this.form
          }).then(response => {
            this.btnLoading = false
            this.showEdit = false
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration: 2000
            })
            this.getList();
          }).catch((err) => {
            this.btnLoading = false
          })
        }
      })
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.img {
  width: 40px;
  height: 40px;
}
</style>
